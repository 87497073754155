import React from 'react';

import { Link } from 'react-router-dom';

export interface MagicLinkProps {
  n: number;
}

const MagicLink: React.FunctionComponent<MagicLinkProps> = (props: React.PropsWithChildren<MagicLinkProps>): React.ReactElement => (
  <Link to={`/four-is-magic?input=${props.n}`} title={`${props.n} is 4`}>{props.children}</Link>
);

export default MagicLink;
