import React from 'react';

import './entry.css';

interface EntryProps {
  title: string;
  date: string;
  retraction?: string;
  note?: string | React.ReactElement;
}

const Entry: React.FunctionComponent<EntryProps> = (props: React.PropsWithChildren<EntryProps>): React.ReactElement => (
  <article className={`entry${props.hasOwnProperty("retraction") ? ' retracted' : ''}`}>
    <h3 className="header" >{props.title}</h3>
    <h4 className="date">{props.date}</h4>
    {
      props.hasOwnProperty("retraction")
      ? <h3 className="retracted">Retracted</h3>
      : null
    }
    {props.children}
    <h3 className="conclusion">SHE WAS WRONG</h3>
    {
      props.hasOwnProperty("retraction")
      ? <h6 className="retraction">{props.retraction}</h6>
      : null
    }
    {
      props.hasOwnProperty("note")
      ? <h6 className="retraction">{props.note}</h6>
      : null
    }
  </article>
);

export default Entry;
