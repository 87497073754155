
import React from 'react';

import { Banner, Entry, MagicLink as ML, Celebration } from './../components';

/*
May 11, 2021: Alexis said she would pay Jack $100/year for up to 5 years if the new Mariners Santa Ana campus survives
*/

const banners: Array<string> = [
  "https://alexiswaswrong.com/images/Alexis 1.jpeg",
  "https://alexiswaswrong.com/images/Alexis 2.jpeg",
  "https://alexiswaswrong.com/images/Alexis 3.jpeg",
  "https://alexiswaswrong.com/images/Alexis 4.jpeg",
  "https://alexiswaswrong.com/images/Alexis 5.jpeg",
  "https://alexiswaswrong.com/images/Alexis 6.jpeg",
  "https://alexiswaswrong.com/images/Alexis 7.jpeg"
];
const getBanner = () => {
  let r = Math.random();
  return banners[Math.floor(r * banners.length)];
};

function IndexPage() {
  return (
    <div className="page">
      <Banner src={getBanner()} heading="Alexis Was Wrong" subheading="All of the times that Alexis was wrong"/>
      <Entry title="The Significant Date" date="September 25th, 2022" note={<span>Leah's birthday is January <ML n={31}>31<sup>st</sup></ML></span>}>
        <p>
          On September <ML n={25}>25<sup>th</sup></ML> <ML n={2022}>2022</ML> Alexis said that Leah's birthday was on January <ML n={25}>30<sup>th</sup></ML>.
        </p>
      </Entry>
      <Entry title="Christmas Day" date="November 14th, 2021" note={<span>Christmas <ML n={2021}>2021</ML> was on a Saturday</span>}>
        <p>On November <ML n={14}>14<sup>th</sup></ML> <ML n={2021}>2021</ML> Alexis said that Christmas was on a Thursday.</p>
      </Entry>
      <Entry title="The Beginning" date="May 13th, 2021" note={<span>We started on March <ML n={15}>15<sup>th</sup></ML> <ML n={2020}>2020</ML></span>}>
        <p>
          On May <ML n={13}>13<sup>th</sup></ML> <ML n={2021}>2021</ML> (day <ML n={424}>424</ML> of quarantine) Alexis said that we track days of quarantine based
          on when quarantine officially started in the state of California (which by the way, has <ML n={39000000}>39 million</ML> inhabitants,
          not <ML n={11000000}>11 million</ML>, see "The Population") on March <ML n={19}>19<sup>th</sup></ML> <ML n={2020}>2020</ML>.
        </p>
      </Entry>
      <Entry title="The (non)Industry-Specific Pronunciation" date="May 11th, 2021" note={<span></span>}>
        <p>On May <ML n={11}>11<sup>th</sup></ML> 2021 (day <ML n={422}>422</ML> of quarantine) it was discovered that Alexis pronounces the word jargon "jargän."</p>
      </Entry>
      <Celebration heading="Celebrating 1 Year of Alexis Being Wrong"/>
      <Entry title="The Rules" date="February 11th, 2021" note={<span>There is only <ML n={1}>one</ML> rule; that Alexis was wrong.</span>}>
        <p>On February <ML n={11}>11<sup>th</sup></ML> <ML n={2021}>2021</ML> (day <ML n={333}>333</ML> of quarantine) Alexis said that there are rules about what qualifies for an <a href="https://alexiswaswrong.com" title="super useful link">Alexis Was Wrong</a> entry.</p>
      </Entry>
      <Entry title="The Keys" date="January 27th, 2021" note={<span>The keys were in her room.</span>}>
        <p>On January <ML n={27}>27<sup>th</sup></ML> <ML n={2021}>2021</ML> (day <ML n={318}>318</ML> of quarantine) Alexis claimed to have returned her dad's keys.</p>
      </Entry>
      <Entry title="The Distance" date="January 3rd, 2021" note={<span>Denver, Colorado is <ML n={1025}>1025</ML> miles away, Hamilton, Montana is <ML n={1175}>1175</ML> miles away</span>} retraction="Well this is embarrassing...">
        <p>On January <ML n={3}>3<sup>rd</sup></ML> <ML n={2021}>2021</ML> (day <ML n={294}>294</ML> of quarantine), Alexis said that Colorado is closer than Montana.</p>
      </Entry>
      <Entry title="The Ratio" date="May 28th, 2020" note={<span>She said that for every <ML n={3}>3</ML> guys there are <ML n={10}>10</ML> girls</span>}>
        <p>On May <ML n={28}>28<sup>th</sup></ML> <ML n={2020}>2020</ML> (day <ML n={74}>74</ML> of quarantine), Alexis said that Leah said that there are more guys than girls at Concordia.</p>
      </Entry>
      <Entry title="The Population" date="May 7th, 2020" note={<span>There are <ML n={39000000}>39 million</ML></span>}>
        <p>On May <ML n={7}>7<sup>th</sup></ML> <ML n={2020}>2020</ML> (day <ML n={53}>53</ML> of quarantine), Alexis said that there were <ML n={11000000}>11 million</ML> people in the state of California.</p>
      </Entry>
      <Entry title="The Darts Game" date="April 12th, 2020">
        <p>On April <ML n={12}>12<sup>th</sup></ML> <ML n={2020}>2020</ML> (day <ML n={28}>28</ML> of quarantine), Alexis said that she would beat Chloe at Darts.</p>
      </Entry>
      <Entry title="The Mayonnaise Incident" date="March 8th, 2020" retraction="There was mayonnaise behind the coldbrew...">
        <p>On March <ML n={8}>8<sup>th</sup></ML> <ML n={2020}>2020</ML>, Alexis said there was mayonnaise in her refrigerator.</p>
      </Entry>
      <Entry title="The Advice" date="February 23rd, 2020">
        <p>On February <ML n={23}>23<sup>rd</sup></ML> <ML n={2020}>2020</ML>, Alexis told Joanna that interactions with her flirtationship partner would be awkward after a weekend of particularly flirty texting.</p>
      </Entry>
    </div>
  );
}

export default IndexPage;
