import React from 'react';
import { Link } from 'react-router-dom';

import './menu.css';

interface MenuProps {
  className?: string;
  dark?: boolean;
}

const Menu: React.FunctionComponent<MenuProps> = (props: React.PropsWithChildren<MenuProps>): React.ReactElement => (
  <nav className={"menu" + (props.dark ? " dark" : "")}>
    <Link to="/">Home</Link>
    <Link to="/four-is-magic">4 is Magic</Link>
  </nav>
);

export default Menu;
