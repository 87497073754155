import React from 'react';
import { useHistory } from 'react-router-dom';

const ones: Array<string> = ["One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
const teens: Array<string> = ["Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
const tens: Array<string> = ["Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
const powers: Array<string> = [
  "Thousand", "Million", "Billion", "Trillion", "Quadrillion", "Quintillion", "Sextillion", "Septillion", "Octillion", "Nonillion",
  "Decillion", "Undecillion", "Duodecillion", "Tredecillion", "Quadrecillion", "Quintecillion", "Sexdecillion", "Septdecillion", "Octodecillion", "Nonodecillion"
  ];

const digitsRegexp: RegExp = /^\d*$/;

const expandifyNumber = (val: string): string => {
  if (val === "0") return "zero";
  let r: string = "";
  for (let i = 0; i < val.length; i += 3) {
    let part: string = val.substring(val.length - i - 3, val.length - i);
    let p: string = "";
    let asNum: number = Number.parseInt(part);
    if (asNum > 99) {
      p += ones[Math.floor(asNum / 100) - 1];
      p += "Hundred";
      asNum = asNum % 100;
      
    } 
    if (asNum > 19) {
      p += tens[Math.floor(asNum / 10) - 1];
      asNum = asNum % 10;
      
    } else if (asNum > 10) {
      p += teens[(asNum % 10) - 1];
      asNum = 0;
      
    } else if (asNum === 10) {
      p += "Ten";
      asNum = 0;
    }
    if (asNum > 0) {
      p += ones[(asNum % 10) - 1];
    }
    
    let pow: number = (i / 3) - 1;
    if (pow >= 0) {
      p += powers[pow];
    }
    r = p + r;
  }
  return r;
}

const INPUT_QUERY_REGEXP: RegExp = /\?[\S]*(?:input\=(\d+))/;

interface FourProps {
  
}

const doWork = (val: string): string => {
  if (val === "") return "";
  
  let r: string = val;
  let p: string = expandifyNumber(val);
  let iters: number = 0;
  do {
    console.log(p);
    r += " is ";
    r += p.length;
    p = expandifyNumber(`${p.length}`);
    iters++;
  } while (p.length !== 4 || iters < 25);
  r += " because 4 is magic";
  
  return r;
};

const Menu: React.FunctionComponent<FourProps> = (props: React.PropsWithChildren<FourProps>): React.ReactElement => {
  let history = useHistory();
  let fromLocation: string = (() => {
    let location = history.location;
    let match = INPUT_QUERY_REGEXP.exec(location.search);
    if (match === null) {
      return "";
      
    } else {
      return match[1];
    }
    
  })();
  const [input, setInput] = React.useState<string>(fromLocation);
  const [result, setResult] = React.useState<string>(doWork(input));
  
  const onChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    let val: string = e.target.value;
    if (digitsRegexp.test(val) && val.length <= 63) {
      setInput(val);
      setResult(doWork(val));
      history.replace(`/four-is-magic?input=${val}`);
    }
  }, []);
  
  return (
    <div className="page">
      <h1 style={{ color: "#9c0000" }}>4 is Magic</h1>
      <input type="number" onChange={onChange} value={input} style={{ width: "50%" }}/>
      <p>{result}</p>
    </div>
  );
};

export default Menu;
