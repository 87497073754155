import React from 'react';
import './App.css';

import { HashRouter as Router, Switch, Route } from 'react-router-dom';

import IndexPage from './pages/index';
import FourPage from './pages/fourIsMagic';

import { Menu } from './components';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={IndexPage}/>
          <Route exact path="/four-is-magic" component={FourPage}/>
        </Switch>
        <Menu dark/>
      </Router>
    </div>
  );
}

export default App;
