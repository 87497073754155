import React from 'react';
import './banner.css';

interface BannerProps {
  src: string;
  heading: string;
  subheading?: string;
}

const Banner: React.FunctionComponent<BannerProps> = (props: React.PropsWithChildren<BannerProps>): React.ReactElement => {
  const backgroundStyle = {
    backgroundImage: `url("${props.src}")`
  };
  return (
    <div className="banner">
      <div className="background" style={backgroundStyle}></div>
      <div className="headings">
        <h1 className="heading">{props.heading}</h1>
        {
          props.hasOwnProperty("subheading")
          ? <h2 className="subheading">{props.subheading}</h2>
          : null
        }
      </div>
    </div>
    );
};

export default Banner;
