import React from 'react';
import './celebration.css';

interface CelebrationProps {
  heading: string;
}

const URLS: Array<string> = [
  "https://i.gifer.com/Ace.gif",
  "https://i.pinimg.com/originals/ec/6b/ee/ec6bee97453d3601cc649f1ab22c68f9.gif",
  "https://bestanimations.com/media/fireworks2/2103885627fireworks-animation-33.gif"
];

const Celebration: React.FunctionComponent<CelebrationProps> = (props: React.PropsWithChildren<CelebrationProps>): React.ReactElement => {
  return (
    <div className="celebration">
        <div className="pad"></div>
        <img src="https://i.pinimg.com/originals/ec/6b/ee/ec6bee97453d3601cc649f1ab22c68f9.gif"/>
        <div className="pad"></div>
        <img className="img-2" src="https://bestanimations.com/media/fireworks2/2103885627fireworks-animation-33.gif"/>
        <div className="pad"></div>
        <h1>{props.heading}</h1>
        <div className="pad"></div>
        <img className="img-2" src="https://bestanimations.com/media/fireworks2/2103885627fireworks-animation-33.gif"/>
        <div className="pad"></div>
        <img className="img-1" src="https://i.pinimg.com/originals/ec/6b/ee/ec6bee97453d3601cc649f1ab22c68f9.gif"/>
        <div className="pad"></div>
    </div>
    );
};

export default Celebration;
